.lkDashBoard {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .lkCardsContainer{
    border: none;
    border-radius: 16px;
    width: 100%;

    .ant-card-head{
      border-bottom: none;
      .ant-card-head-title{
        //styleName: Title/Text - Title 3;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 600;
        line-height: 21.94px;
        text-align: left;
        color: var(--Text-text-black, #FDFDFD);

    
      }
    }
  }

  // &.dark{
  //   .lkCardsContainer{
  //     .ant-card-head-title{
  //       color: var(--Text-text-black, #929396);
  //     }
  //   }
  // }
  // &.light{
  //   .lkCardsContainer{
  //     .ant-card-head-title{
  //       color: var(--Text-text-black, #121724);
  //     }
  //   }
  // }
}